import { useEffect, useState } from 'react'
import colors from '@/constants/colors'
import { cx } from '@/utils/strings'

export type EventProps = {
  title: string
  startTime?: string
  isCustomTime?: boolean
  customTime?: string
  category?: string
  speakers?: string[]
  date: string
  timeZone?: string
  backgroundColor?: string
}

const partsToTimeString = (parts: Intl.DateTimeFormatPart[]) =>
  parts
    .slice(0, -2)
    .map((item) => item.value)
    .join('')

// Format start and end time to h:MM - h:MMa/p
const formatTime = (startTime: string, date: string, timeZone?: string) => {
  if (!startTime || !date) return ''
  if (!timeZone) timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  try {
    const startDate = new Date(date.replace(/T\d\d:\d\d/, `T${startTime}`))
    const formatter = new Intl.DateTimeFormat(undefined, {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZone,
    })
    const startParts = formatter.formatToParts(startDate)
    const start = partsToTimeString(startParts)
    const amPm = startParts?.pop()?.value.toLowerCase()
    return `${start}${amPm}`
  } catch {
    return ''
  }
}

const categoryColors: Record<string, string> = {
  keynote: `bg-[#DBE5F6]`,
  presentation: `bg-[#FFEDB6]`,
  fireside: `bg-[#FFCDD3]`,
  panel: `bg-[#8AECF3]`,
  'case study': `bg-primaryBlue text-white`,
}

export default function Event({
  title,
  startTime,
  isCustomTime = false,
  customTime,
  category,
  speakers: _speakers,
  date,
  timeZone,
  backgroundColor = colors.white,
}: EventProps) {
  // Have to use state + effect because of SSR hydration issues with datetime
  const [time, setTime] = useState('')
  useEffect(() => {
    if (!startTime && !isCustomTime) return
    setTime(isCustomTime ? customTime || '' : formatTime(startTime || '', date, timeZone))
  }, [startTime, isCustomTime, customTime, timeZone])

  const speakers = _speakers?.filter((speaker) => speaker)

  return (
    <div className="grid gap-3 grid-cols-[131px_1fr] items-start">
      <div className="border-t-[1px] border-gray-400 py-2 mt-6">
        <div>{time}</div>
        {category && (
          <div
            className={cx(
              `text-xs text-center uppercase rounded p-1 w-auto inline-block`,
              categoryColors[category.toLowerCase()] || `bg-gray-300`
            )}
          >
            {category}
          </div>
        )}
      </div>
      <div className={cx(`p-3 rounded-2xl`, backgroundColor === colors.lightGray ? `bg-white` : `bg-gray-100`)}>
        <h4 className={cx(`text-xl`, (speakers?.length ?? 0) > 0 ? `mb-3` : `mb-0`)}>{title}</h4>
        {speakers?.map((speaker) => (
          <p key={speaker} className="text-base mb-0">
            {speaker}
          </p>
        ))}
      </div>
    </div>
  )
}
